<template>
  <div class="input-holder" :id="`pin-layout-${aliasId}`">
    <div v-for="n in parseInt(boxCount + 1)">
      <div class="input-container">
        <input
        :disabled="disabled"
        :id="`boxed-input-${n - 1}-${aliasId}`"
        @input="onDataChange($event.target.value, n - 1)"
        class="box-input"
        :type="inputType"
        @keydown="validateInput"
        v-model="dataArr[n - 1]"
        :class="{ error: renderError }"
        inputmode="numeric"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pinCount", "value", "disabled", "id", "renderError","inputType"],
  data() {
    return {
      boxCount: 4,
      dataArr: [],
      // inputType: "text",
      inputType: "password",
      aliasId: "",
      parentNode: null,
      focusTimeout: null,
     
    };
  },
  created() {
    if (this.disabled || this.disabled !== undefined) {
      this.inputType = "password";
    }
    if (this.id !== null || this.id !== undefined) {
      this.aliasId = this.id;
    }
  },
  watch: {
    
    isEmpty() {
     if(this.isEmpty==true){
      this.dataArr=  []
     }
    },

    value(newPin, oldPin) {
      if (newPin !== "") {
        return;
      }
      let tempArr = [];
      for (let i = 0; i < parseInt(this.boxCount); i++) {
        let slug = "";
        tempArr.push(slug);
      }
      this.dataArr = [...tempArr];
    },
  },
  methods: {
    clearOtpBoxes() {
    this.dataArr = Array(this.boxCount).fill("");
    },

    onFocusChange(event) {
      if (this.focusTimeout !== null) {
        clearTimeout(this.focusTimeout);
      }
      let enteredValue = document.activeElement.value;

      this.focusTimeout = setTimeout(() => {
        this.focusTimeout = null;
        let alphanumericCheck = enteredValue !== "" && !isNaN(enteredValue);
        let keyCode = event.keyCode == 0 ? event.charCode : event.keyCode;
        if (keyCode == 8 || keyCode == 46) {
          //delete of backspace was pressed
          this.moveFocus(false);
        } else if (keyCode == 37) {
          //left arrow key was pressed
          this.moveFocus(false);
        } else if (keyCode == 39) {
          //right arrow was pressed
          this.moveFocus(true);
        } else if (alphanumericCheck) {
          //alphanumeric key was pressed
          this.moveFocus(true);
        }
      }, 5);
    },
    moveFocus(isForward) {
      let children = this.parentNode.children;
      let nextFocusInput = null;
      let previousFocusInput = null;
      let focusedInput = document.activeElement;
      for (let i = 0; i < children.length; i++) {
        let input = children[i].children[0].children[0];
        if (input === focusedInput) {
          try {
            nextFocusInput = children[i + 1].children[0].children[0];
          } catch (IndexOutOfRangeError) {
            //this means we have reached the last element
            nextFocusInput = null;
          }
          try {
            previousFocusInput = children[i - 1].children[0].children[0];
          } catch (IndexOutOfRangeError) {
            //this means we have reached the first element
            previousFocusInput = null;
          }
        }
      }

      if (isForward) {
        if (nextFocusInput !== null && nextFocusInput !== undefined) {
          nextFocusInput.focus();
        }
      } else {
        if (previousFocusInput !== null && previousFocusInput !== undefined) {
          previousFocusInput.focus();
        }
      }
    },
    validateInput(event){
      let key = event.key;
      if (key === "Backspace" || key === "Delete") {
        return; 
      }
      if(isNaN(key)){
        event.preventDefault();
      } 
    },

    onDataChange(value, index) {
      let temp = value;
      if (temp.length > 1) {
        temp = temp[0];
      }
      let tempArr = this.dataArr;
      tempArr[index] = temp;
      this.dataArr = [...tempArr];
      /*
            try {
        if (temp !== "") {
          document.getElementById(`boxed-input-${index + 1}-${this.aliasId}`).focus();
        }
        else{
          try{
            document.getElementById(`boxed-input-${index - 1}-${this.aliasId}`).focus();
            return;
          }
          catch(err){
            return;
          }
        }
      } catch (error) {
        // index out of range.. that means the end of the inputs has reached. do nothing here
      }
      */

      let enteredInput = "";
      for (let i = 0; i < tempArr.length; i++) {
        if (
          tempArr[i] !== null &&
          tempArr[i] !== undefined &&
          tempArr[i] !== ""
        ) {
          enteredInput = enteredInput + tempArr[i];
        }
      }
      if (enteredInput.length > this.boxCount) {
        this.$emit("onCompleted", enteredInput);
      } else {
        this.$emit("onChange", enteredInput);
      }
    },
  },
  mounted() {
    if (
      this.pinCount != null &&
      this.pinCount != undefined &&
      this.pinCount != ""
    ) {
      let count = parseInt(this.pinCount) - 1;
      this.boxCount = count;
    }

    let tempArr = [];
    for (let i = 0; i < this.pinCount; i++) {
      let temp = "";
      if (
        (this.value !== null &&
          this.value !== undefined &&
          this.value[i] !== undefined &&
          this.value[i] !== null) ||
        this.value[i] !== undefined
      ) {
        temp = this.value[i];
      }
      tempArr.push(temp);
    }
    this.dataArr = tempArr;

    let parentContainer = document.getElementById(`pin-layout-${this.aliasId}`);
    this.parentNode = parentContainer;
    this.parentNode.addEventListener("keyup", this.onFocusChange);
  },
  destroyed() {
    this.parentNode.removeEventListener("keyup", this.onFocusChange);
  },
};
</script>
<style lang="scss" scoped>
@import "./PinLayout.scss"
</style>
